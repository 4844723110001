var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('r-app',{attrs:{"sidebar-menu-data":{
      menus: _vm.menus,
      bottomMenus: _vm.bottomMenus,
      defaultIcon: 'brightness-1',
      logoUrl: '/images/icons/favicon-96x96.png',
      settings: {
        showedMenus: {
          firstLevel: 6,
          secondLevel: 2,
        },
      },
    },"is-loading-sidebar-menu":_vm.isLoadingSidebarMenu},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('r-notification',{key:1,attrs:{"notification-data":_vm.notification,"is-active":_vm.isNotificationActive,"is-loading":_vm.isLoadingNotification,"tabs":['Notification']},on:{"showAllNotifications":function () { return (_vm.isActive = true); },"infiniteHandler":_vm.onInfiniteScroll},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('b-button',{staticClass:"is-command",on:{"click":_vm.markAllAsRead}},[_vm._v(" Mark All as Read ")])]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('r-notification-item',{attrs:{"item":item,"is-unread":item.isRead === 0},on:{"click":function($event){return _vm.readNotif(item.id)}},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('strong',[_vm._v(_vm._s(item.fullName))]),_vm._v(" "+_vm._s(item.message)+" ")]},proxy:true},{key:"detail",fn:function(){return [_c('div',{staticClass:"notification-hr-action"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"category"},[(_vm.mapCategoryNotif.get(item.category))?_c('div',[_c('b-icon',{attrs:{"icon":_vm.mapCategoryNotif.get(item.category).icon}}),_vm._v(" "+_vm._s(_vm.mapCategoryNotif.get(item.category).name)+" ")],1):_c('div',[_vm._v(" "+_vm._s(item.category)+" ")])]),(item.requestDate)?_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(item.requestDate)+" ")]):_vm._e()]),(item.requestReason)?_c('div',{staticClass:"message"},[_vm._v(" Reason: "),_c('br'),_vm._v(" "+_vm._s(item.requestReason)+" ")]):_vm._e()])]},proxy:true}],null,true)})]}}]),model:{value:(_vm.activeNotificationTab),callback:function ($$v) {_vm.activeNotificationTab=$$v},expression:"activeNotificationTab"}}),(_vm.currentUser != null)?_c('r-profile',{attrs:{"is-online":_vm.isOnline,"userData":{
          name: _vm.currentUser.fullName,
          role: _vm.formatPositionName(_vm.currentUser),
          profilePicture: _vm.determineProfilePicture(_vm.currentUser),
        },"isOnline":_vm.currentUser.workingStatus === 'online',"user-page-link":'/settings/personal-info',"settings-page-link":'/settings/personal-info'}},[_c('b-dropdown-item',{attrs:{"separator":""}}),(!_vm.isLoggingOut)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","tag":"b-button"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")]):_c('b-dropdown-item',{attrs:{"aria-role":"listitem","tag":"b-button"}},[_vm._v(" Logging out... ")])],1):_vm._e()]},proxy:true},{key:"default",fn:function(){return [_c('router-view')]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"}),_c('div',{staticClass:"level-right"},[_vm._v("Base Project Roketin v0.4.0")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }