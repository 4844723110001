// version
export const version = '3.1.14'

export const relationshipOption = [
  'Adik',
  'Anak',
  'Ayah',
  'Bibi',
  'Ibu',
  'Istri',
  'Kakak',
  'Keponakan',
  'Menantu',
  'Mertua',
  'Nenek',
  'Paman',
  'Saudara',
  'Suami',
  'Kakek',
]

export const genders = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
]

export const bloodTypes = ['A', 'B', 'O', 'AB']

export const maritalStatusses = [
  'Belum Kawin',
  'Kawin',
  'Cerai Hidup',
  'Cerai Mati',
]

export const religions = [
  'islam',
  'kristen',
  'katholik',
  'hindu',
  'buddha',
  'konghuchu',
]

export const contractTypes = [
  {
    value: 'fulltime',
    name: 'Fulltime',
  },
  {
    value: 'parttime',
    name: 'Parttime',
  },
  {
    value: 'permanent',
    name: 'Permanent',
  },
  {
    value: 'freelancer',
    name: 'Freelancer',
  },
  {
    value: 'internship',
    name: 'Internship',
  },
]

export const statusRequest = [
  {
    value: 'waiting',
    name: 'Waiting',
  },
  {
    value: 'accepted' || 'approved',
    name: 'Approved',
  },
  {
    value: 'declined',
    name: 'Declined',
  },
  {
    value: 'paid',
    name: 'Paid',
  },
]

export const announcementCategories = [
  {
    value: 'general',
    name: 'General',
  },
  {
    value: 'internal',
    name: 'Internal',
  },
  {
    value: 'news',
    name: 'News',
  },
]

//* Notification module type. List your notification module type here !
export const NOTIF_ANNOUNCEMENT = 'announcement'
export const NOTIF_REQUEST_OVERTIME = 'request_overtime'
export const NOTIF_REQUEST_TIMEOFF = 'request_timeoff'
export const NOTIF_REQUEST_CHANGE_SCHEDULE = 'request_change_schedule'
export const NOTIF_CHANGE_SCHEDULE = 'change_schedule'
export const NOTIF_REQUEST_REIMBURSEMENT = 'request_reimbursement'
export const NOTIF_REQUEST_PROFILE_CHANGE = 'request_profile_change'
export const NOTIF_REQUEST_PROFILE_CHANGE_ADDRESS =
  'request_profile_change_address'
export const NOTIF_REQUEST_FAMILY = 'request_family'
export const NOTIF_REQUEST_FAMILY_ADDRESS = 'request_family_address'
export const NOTIF_REQUEST_EMERGENCY_CONTACT = 'request_emergency_contact'
export const NOTIF_REQUEST_HEALTH = 'request_health'
export const NOTIF_REQUEST_HEALTH_FOOD_ALLERGY = 'request_health_food_allergies'
export const NOTIF_REQUEST_HEALTH_DIETARY_PREFERENCE =
  'request_health_dietary_preferences'
export const NOTIF_REQUEST_HEALTH_HEREDITY_ILLNESS =
  'request_health_heredity_illness'
export const NOTIF_REQUEST_EDUCATION = 'request_education'
export const NOTIF_REQUEST_INFORMAL_EDUCATION =
  'request_education_informal_education'

export const ACTIVITY_ATTENDANCE_CHECKIN = 'attendance_check_in'
export const ACTIVITY_ATTENDANCE_CHECKOUT = 'attendance_check_out'
export const NOTIF_REQUEST_WFO = 'request_wfo'
export const NOTIF_REQUEST_ADJUSTMENT_ATTENDANCE =
  'request_attendance_adjustment'

export const NOTIF_REQUEST_EMPLOYEE_INFO = 'request_employee_info'

export const NOTIF_REQUEST_ATTENDANCE_ACTIVITY_ADJUSTMENT =
  'request_attendance_activity_adjustment'
export default {
  defaultImageUrl:
    'https://cdn.fastly.picmonkey.com/contentful/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=800&q=70',
}

const mapCategoryNotif = new Map()
mapCategoryNotif.set(NOTIF_REQUEST_WFO, {
  name: 'WFO',
  icon: 'office-building',
})
mapCategoryNotif.set(NOTIF_REQUEST_TIMEOFF, {
  name: 'Time Off',
  icon: 'alarm-off',
})
mapCategoryNotif.set(NOTIF_REQUEST_OVERTIME, {
  name: 'Overtime',
  icon: 'clock-time-four-outline',
})
mapCategoryNotif.set(NOTIF_REQUEST_CHANGE_SCHEDULE, {
  name: 'Change Schedule',
  icon: 'update',
})
mapCategoryNotif.set(NOTIF_CHANGE_SCHEDULE, {
  name: 'Change Schedule',
  icon: 'update',
})
mapCategoryNotif.set(NOTIF_REQUEST_REIMBURSEMENT, {
  name: 'Reimbursement',
  icon: 'cash',
})
mapCategoryNotif.set(NOTIF_REQUEST_ADJUSTMENT_ATTENDANCE, {
  name: 'Adj. Attendance',
  icon: 'calendar',
})

mapCategoryNotif.set(NOTIF_REQUEST_EMPLOYEE_INFO, {
  name: 'Request Employee Info',
  icon: 'account-details',
})

mapCategoryNotif.set(NOTIF_REQUEST_ATTENDANCE_ACTIVITY_ADJUSTMENT, {
  name: 'Request Activity Adjustment',
  icon: 'av-timer',
})

export { mapCategoryNotif }
